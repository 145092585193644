<template>
  <div class="self-read">
    <selfCard :dataInfoArr="dataInfoArr" :loading="loading" />
  </div>
</template>

<script>
import { selectByCustRealId } from '@/api/home';
import { queryUserAndMreadInfo } from '@/api/business';
import selfCard from './module/selfCard';
export default {
  name: 'SelfReadMeter',
  components: { selfCard },
  props: {},
  data() {
    return { dataInfoArr: [], loading: false };
  },
  computed: {},
  mounted() {
    this.queryUserAndMreadInfo();
  },
  methods: {
    async getDataInfo() {},
    async queryUserAndMreadInfo() {
      this.loading = true;
      try {
        let res = await selectByCustRealId();
        let codes = res.resultData.myWaterList.map((item) => item.wuserNo);
        if (codes?.length) {
          let { resultData } = await queryUserAndMreadInfo({ codes });
          this.dataInfoArr = resultData;
        } else {
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="less" scoped></style>
