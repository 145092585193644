<template>
  <div>
    <div class="self-news" v-for="(item, index) in dataInfoArr" :key="index">
      <!-- <div class="self-news-state" v-if="item.closeState === '01'">欠费停水</div> -->
      <div class="self-news-user">
        <div class="self-news-user-name">
          <span class="label-icon"> {{ item.labelName }}</span> {{ item.clientName }} 户号：{{ item.clientCode }}
        </div>
        <div style="margin-bottom:8px">
          <span>水表编号：</span>
          <span>{{ item.meterCode }}</span>
        </div>
        <div class="self-news-user-address van-multi-ellipsis--l3">
          <em class="info-customer-label" v-if="item.signName && item.signName.length > 0">{{ item.signName }}</em>
          <van-icon name="location" color="#4884F0" />
          <span class="info-customer-news">{{ item.meterAddress }}</span>
        </div>
      </div>
      <div class="self-news-notes">
        <!-- <div class="self-news-notes-left">
          <span>{{ item.readCycle }}抄表</span>
          <div v-if="item.showStatus === '01'">
            <span>{{ item.readDays }}日</span>后抄表
          </div>
        </div> -->
        <div class="self-news-notes-right">
          <div class="snnr-read" @click="readRecord(item)">抄表记录</div>

          <div class="snnr-meter snnr-check" v-if="item.noticeBusiStatus === '00'">审核中</div>
          <div class="snnr-meter" style="color:red" v-else-if="item.noticeBusiStatus === '02'" @click="readMeter(item)">
            重新抄表
          </div>
          <div class="snnr-meter" v-else @click="readMeter(item)">立即抄表</div>
        </div>
      </div>
      <div class="fail-info" v-if="item.noticeBusiStatus === '02'">
        <div>尊敬的用户，您的自助抄表审核不通过，不通过原因：{{ item.examineRemarks }}。请重新抄表</div>
      </div>
      <div class="self-news-cost" v-if="item.arrears">
        <div>
          <div class="self-news-cost-money">未缴账单： ¥{{ item.arrears }}</div>
          <!-- <div>
                        <van-checkbox
                            class="self-news-cost-checkbox"
                            v-model="item.checked"
                            shape="square"
                            icon-size="15px"
                        >
                            预存可抵：¥{{item.preDepositBuckle}}
                        </van-checkbox>
                    </div> -->
        </div>
        <div>
          <van-button class="self-news-cost-button" plain type="info" @click="immediatelyPay(item.userNo)">
            立即缴费
          </van-button>
        </div>
      </div>
    </div>
    <div class="submit-button" v-if="!dataInfoArr.length && !loading">
      <div class="empty-content">您暂未绑定任何水表</div>
      <van-button type="info" @click="jumpBind">绑定新用水账户</van-button>
    </div>
  </div>
</template>

<script>
import store from '@/store';
export default {
  props: {
    dataInfoArr: {
      type: Array,
      require: true,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selfState: true, // 是否可以抄表
      oweState: true, // 欠费状态
      checked: false
    };
  },
  mounted() {
    // console.log('AA--数据', this.dataInfoArr)
    console.log('route', this.$route.query);
  },
  methods: {
    readRecord(item) {
      this.$router.push({
        // name: 'MeterReadingRecord',
        path: '/ReadRecord',
        query: { info: JSON.stringify(item) }
      });
    },
    readMeter(item) {
      console.log('item', item);
      this.$router.push({
        path: '/ReadMeter',
        name: 'ReadMeter',
        query: {
          info: JSON.stringify(item)
        }
      });
    },
    immediatelyPay(userNo) {
      this.$router.push({
        name: 'PaymentPage',
        path: '/QueryPay/PaymentPage',
        query: {
          wuserNo: userNo
        }
      });
    },
    async jumpBind() {
      // this.code = '0014'; // code: 00 -> 约定成业务里面的
      let data = {
        url: '/Business/selfReadMeter',
        code: ''
      };
      await store.dispatch('user/savePathUrl', data);
      this.$router.push({ path: '/Bind' });
    }
  }
};
</script>

<style lang="less" scoped>
.self-news {
  margin: 16px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  box-shadow: 0 6px 16px 0 rgba(63, 70, 79, 0.12);
  &-state {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1px 6px;
    background: #f64357;
    border-radius: 0 8px 0 8px;
    font-family: PingFangSC-Medium;
    font-size: 12px;
    color: #ffffff;
    letter-spacing: -0.3px;
  }
  &-user {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 16px;
    padding-bottom: 0;
    display: block;

    .label-icon {
      padding: 4px 6px;
      font-size: 14px;
      background-color: #edf2ff;
      border-radius: 8px;
      color: #ae91c9;
    }
    &-icon {
      color: #0e6bf9;
      margin-right: 6px;
    }
    &-tag {
      font-family: PingFangSC-Medium;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      margin-right: 6px;
    }
    &-division {
      color: #aeaeae;
      margin-right: 6px;
    }
    &-name {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 700;
      margin-bottom: 8px;
    }
    &-address {
      padding-top: 2px;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #000000;
      em {
        font-style: normal;
        background: #ecf1fe;
        border-radius: 2px;
        border-radius: 2px;
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #637fb7;
        letter-spacing: 0;
        line-height: 16px;
        padding: 1px 5px;
        margin-right: 5px;
      }
    }
  }

  &-notes {
    padding: 0 16px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    &-left {
      display: flex;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
      div {
        margin-left: 6px;
      }
      div > span {
        color: #f78b4d;
      }
    }
    &-right {
      display: flex;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #1d6fe9;
      .snnr-read {
        padding: 16px 8px 16px 16px;
      }
      .snnr-meter {
        padding: 16px 0 16px 8px;
      }
    }
  }
  .self-news-notes::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 16px;
    top: 0;
    left: 16px;
    border-top: 1px solid #ebedf0;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
  &-cost {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px 16px 16px;
    position: relative;
    &-money {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
    }
    /deep/ .self-news-cost-checkbox .van-checkbox__label {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
    }
    &-button {
      border-radius: 4px;
      // width: 80px;
      height: 32px;
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: #1d6fe9;
    }
  }
  .self-news-cost::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 16px;
    top: 0;
    left: 16px;
    border-top: 1px solid #ebedf0;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
}
.info-customer-news {
  margin-left: 8px;
}
.submit-button {
  text-align: center;
}
.snnr-check {
  color: #f78b4d;
}
.fail-info {
  display: flex;
  align-items: center;
  padding: 4px 12px;
  min-height: 40px;
  font-size: 12px;
  color: #fc882c;
  background-color: #fff6f0;
}
</style>
